<template>
  <dashboard-chart
    class="weekly-planning-dashboard-chart-ibp-demand-fullfilment"
    :base-filter="baseFilter"
    :on-load="onLoad"
    :on-export="onExport"
  >
  </dashboard-chart>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import { DashboardChart } from '../dashboards'
import service from '../../api/weeklyPlanningServiceRevised'

export default defineComponent({
  name: 'WeeklyPlanningDashboardChartIbpDemandFullfilmentRevised',
  components: { DashboardChart },
  props: {
    baseFilter: {
      type: Object,
      required: true
    }
  },
  methods: {
    async onLoad(chartReference) {
      const chart = await service.getIbpDemandFullfilment(
        chartReference,
        this.baseFilter
      )
      chart.legend = { legendNameFormatter: (x) => x.userOptions.stack }

      return chart
    },
    async onExport(chartReference) {
      await service.getIbpDemandFullfilmentExcel(
        chartReference,
        this.baseFilter
      )
    }
  }
})
</script>
