<template>
  <data-box custom>
    <p class="align-left">{{ title }}</p>
    <div
      class="inline-box-text"
      v-for="(detail, index) in details"
      :key="index + '-inline-box-text-' + title"
    >
      <div>{{ detail.label }}:</div>
      <div>
        <p
          class="inline-box-value"
          v-for="(value, index) in detail.values"
          :key="index + '-inline-box-value-' + title + value"
        >
          {{ value }}
        </p>
      </div>
    </div>
  </data-box>
</template>
<script>
import DataBox from './DataBox.vue'

export default {
  name: 'WeeklyDashboardDetailBox',
  components: {
    DataBox
  },
  props: {
    title: {
      type: String,
      default: null
    },
    details: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.inline-box-text {
  display: flex;
  width: 100%;
  grid-gap: 10px;
  margin-top: 3px;
  align-items: center;
}

.inline-box-value {
  font-weight: bold;
  color: #24bbef;
  font-size: 0.78vw;
}

.align-left {
  text-align: left;
}
</style>
