<template>
  <cargill-section-header class="weekly-planning-dashboard-header">
    <div>
      <span>
        {{ $t('application.pages.weeklyPlanningDashboard.headerTitle') }}
      </span>
      <bc-divider vertical class="mx-2"></bc-divider>
      <span>
        {{ $t('application.pages.monthlyPlanningDashboardRevised.horizon') }}:
        <span class="font-weight-regular">
          {{ `${horizon.start} ${$t('application.misc.till')} ${horizon.end}` }}
        </span>
      </span>
    </div>

    <div>
      <dashboard-unit-selector v-model="measurementUnit">
      </dashboard-unit-selector>
    </div>
  </cargill-section-header>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import { CargillSectionHeader } from '../cargill-section'
import { DashboardUnitSelector } from '../dashboards'
import { DateFormat, useDateFormatter } from '../../utils/dateFormatter'

export default defineComponent({
  name: 'WeeklyPlanningDashboardHeader',
  components: {
    CargillSectionHeader,
    DashboardUnitSelector
  },
  props: {
    scenario: {
      type: Object,
      required: true
    },
    unit: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formatDate: useDateFormatter(this)
    }
  },
  computed: {
    horizon() {
      return {
        start: this.formatDate(this.scenario.beginHorizon, DateFormat.Date),
        end: this.formatDate(this.scenario.endHorizon, DateFormat.Date)
      }
    },
    measurementUnit: {
      get() {
        return this.unit
      },
      set(value) {
        this.$emit('unit-change', value)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.weekly-planning-dashboard-header {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
</style>
