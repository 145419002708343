import { dashboardUtils } from '@cargill/shared'

const baseURL = 'api/weeklyPlanning'

const service = {}

service.getConsolidated = async (filter) => {
  const url = `${baseURL}/consolidated`
  return await dashboardUtils.filterDashboard(filter, url)
}

service.getIbpDemandFullfilment = async (oldChart, baseFilter) => {
  const url = `${baseURL}/ibpDemandFullfilment`
  const processChart = (chart) =>
    chart.series.forEach((serie, i) => (serie.order = i))
  return await dashboardUtils.filterChart(
    oldChart,
    baseFilter,
    url,
    processChart
  )
}

service.getWeeklyDemandFullfilment = async (oldChart, baseFilter) => {
  const url = `${baseURL}/weeklyDemandFullfilment`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getBookingProjectionWithoutTethering = async (oldChart, baseFilter) => {
  const url = `${baseURL}/bookingProjectionWithoutTethering`
  const processChart = (chart) => (chart.series[1].yAxisID = 'yQuantityBooking')
  return await dashboardUtils.filterChart(
    oldChart,
    baseFilter,
    url,
    processChart
  )
}

service.getSalesWithoutAllocation = async (
  oldChart,
  baseFilter
) => {
  const url = `${baseURL}/salesWithoutAllocation`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getCottonProjectionWithoutAlocation = async (oldChart, baseFilter) => {
  const url = `${baseURL}/cottonProjectionWithoutAlocation`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getAvailableBookingOpenContractsWithoutAlocation = async (
  oldChart,
  baseFilter
) => {
  const url = `${baseURL}/availableBookingOpenContractsWithoutAlocation`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getOpenContractsWithoutAlocationCottonAvailability = async (
  oldChart,
  baseFilter
) => {
  const url = `${baseURL}/openContractsWithoutAlocationCottonAvailability`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getCapacityUtilization = async (oldChart, baseFilter) => {
  const url = `${baseURL}/capacityUtilization`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getLoadingPlan = async (oldChart, baseFilter) => {
  const url = `${baseURL}/loadingPlan`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getTakeOrPayProjection = async (oldChart, baseFilter) => {
  const url = `${baseURL}/takeOrPayProjection`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getOriginationProjection = async (oldChart, baseFilter) => {
  const url = `${baseURL}/originationProjection`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getIbpDemandFullfilmentExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/ibpDemandFullfilmentExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getWeeklyDemandFullfilmentExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/weeklyDemandFullfilmentExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getBookingProjectionWithoutTetheringExcel = async (
  chartRef,
  baseFilter
) => {
  const url = `${baseURL}/bookingProjectionWithoutTetheringExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getSalesWithoutAllocationExcel = async (
  chartRef,
  baseFilter
) => {
  const url = `${baseURL}/salesWithoutAllocationExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getCottonProjectionWithoutAlocationExcel = async (
  chartRef,
  baseFilter
) => {
  const url = `${baseURL}/cottonProjectionWithoutAlocationExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getAvailableBookingOpenContractsWithoutAlocationExcel = async (
  chartRef,
  baseFilter
) => {
  const url = `${baseURL}/availableBookingOpenContractsWithoutAlocationExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getOpenContractsWithoutAlocationCottonAvailabilityExcel = async (
  chartRef,
  baseFilter
) => {
  const url = `${baseURL}/openContractsWithoutAlocationCottonAvailabilityExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getCapacityUtilizationExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/capacityUtilizationExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getLoadingPlanExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/loadingPlanExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getTakeOrPayProjectionExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/takeOrPayProjectionExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getOriginationProjectionExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/originationProjectionExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

export default service
