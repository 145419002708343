<template>
  <dashboard-chart
    class="weekly-planning-dashboard-chart-stuffing-projection"
    :base-filter="baseFilter"
    :on-load="onLoad"
    :on-export="onExport"
    :customOptions="customOptions"
    :supressChartStack="true"
  >
  </dashboard-chart>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { dashboardUtils } from '@cargill/shared'

import { DashboardChart } from '../dashboards'
import service from '../../api/weeklyPlanningServiceRevised'

export default defineComponent({
  name: 'WeeklyPlanningDashboardChartStuffingProjection',
  components: { DashboardChart },
  props: {
    baseFilter: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      customOptions: {
        yAxis: {
          title: dashboardUtils.getAxisTitle('HC')
        }
      }
    }
  },
  methods: {
    async onLoad(chartReference) {
      const chart = await service.getStuffingProjection(
        chartReference,
        this.baseFilter
      )
      return chart
    },
    async onExport(chartReference) {
      await service.getStuffingProjectionExcel(chartReference, this.baseFilter)
    }
  }
})
</script>
