<template>
  <bc-container class="weekly-planning-dashboard">
    <cargill-section>
      <weekly-planning-dashboard-header
        :scenario="scenario"
        :unit="measurementUnit"
        @unit-change="onChangeMeasurementUnit"
      ></weekly-planning-dashboard-header>

      <cargill-section-content>
        <weekly-planning-dashboard-consolidated
          :scenario="scenario"
          :unit="measurementUnit"
        ></weekly-planning-dashboard-consolidated>

        <dashboard-date-filter
          v-model="selectedPeriod"
          :min-date="scenario.beginHorizon"
          :max-date="scenario.endHorizon"
        ></dashboard-date-filter>

        <div class="weekly-planning-dashboard__charts">
          <bc-row dense>
            <bc-col cols="12" lg="4">
              <weekly-planning-dashboard-chart-ibp-demand-fullfilment-revised
                :base-filter="baseFilter"
                :unit="measurementUnit"
              />
            </bc-col>

            <bc-col cols="12" lg="8">
              <weekly-planning-dashboard-chart-demand-fullfilment-revised
                :base-filter="baseFilter"
                :unit="measurementUnit"
              />
            </bc-col>
          </bc-row>

          <bc-row dense>
            <bc-col cols="12" lg="4">
              <weekly-planning-dashboard-chart-booking-projection-without-tethering-revised
                :base-filter="baseFilter"
                :unit="measurementUnit"
              />
            </bc-col>

            <bc-col cols="12" lg="4">
              <weekly-planning-dashboard-chart-sales-without-allocation
                :base-filter="baseFilter"
                :unit="measurementUnit"
              />
            </bc-col>

            <bc-col cols="12" lg="4">
              <weekly-planning-dashboard-chart-available-stock
                :base-filter="baseFilter"
                :unit="measurementUnit"
              />
            </bc-col>
          </bc-row>

          <bc-row dense>
            <bc-col cols="12" lg="6">
              <weekly-planning-dashboard-chart-available-booking-x-available-sale
                :base-filter="baseFilter"
                :unit="measurementUnit"
              />
            </bc-col>

            <bc-col cols="12" lg="6">
              <weekly-planning-dashboard-chart-sales-without-allocation-x-available-stock
                :base-filter="baseFilter"
                :unit="measurementUnit"
              />
            </bc-col>
          </bc-row>
          <bc-row dense>
            <bc-col cols="12" lg="6">
              <weekly-planning-dashboard-chart-storage-projection
                :base-filter="baseFilter"
                :unit="measurementUnit"
              />
            </bc-col>

            <bc-col cols="12" lg="6">
              <weekly-planning-dashboard-chart-stuffing-projection
                :base-filter="baseFilter"
                :unit="measurementUnit"
              />
            </bc-col>
          </bc-row>
          <bc-row dense>
            <bc-col cols="12" lg="12">
              <weekly-planning-dashboard-chart-loading-plan
                :base-filter="baseFilter"
                :unit="measurementUnit"
              />
            </bc-col>
          </bc-row>
          <bc-row dense>
            <bc-col cols="12" lg="6">
              <weekly-planning-dashboard-chart-take-or-pay-projection-storage
                :base-filter="baseFilter"
                :unit="measurementUnit"
              />
            </bc-col>
            <bc-col cols="12" lg="6">
              <weekly-planning-dashboard-chart-take-or-pay-projection-stuffing
                :base-filter="baseFilter"
                :unit="measurementUnit"
              />
            </bc-col>
          </bc-row>
        </div>
      </cargill-section-content>
    </cargill-section>
  </bc-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcContainer } from '@brain/core'
import { useScenarioOpen, dashboardUtils } from '@cargill/shared'

import {
  CargillSection,
  CargillSectionContent
} from '../components/cargill-section'
import { DashboardDateFilter } from '../components/dashboards'
import {
  WeeklyPlanningDashboardHeader,
  WeeklyPlanningDashboardConsolidated,
  WeeklyPlanningDashboardChartDemandFullfilmentRevised,
  WeeklyPlanningDashboardChartIbpDemandFullfilmentRevised,
  WeeklyPlanningDashboardChartBookingProjectionWithoutTetheringRevised,
  WeeklyPlanningDashboardChartStorageProjection,
  WeeklyPlanningDashboardChartStuffingProjection,
  WeeklyPlanningDashboardChartLoadingPlan,
  WeeklyPlanningDashboardChartTakeOrPayProjectionStuffing,
  WeeklyPlanningDashboardChartTakeOrPayProjectionStorage,
  WeeklyPlanningDashboardChartAvailableStock,
  WeeklyPlanningDashboardChartAvailableBookingXAvailableSale,
  WeeklyPlanningDashboardChartSalesWithoutAllocation,
  WeeklyPlanningDashboardChartSalesWithoutAllocationXAvailableStock
} from '../components/weekly-planning-dashboard'

export default defineComponent({
  name: 'WeeklyPlanningDashboardRevised',
  components: {
    BcContainer,
    CargillSection,
    CargillSectionContent,
    DashboardDateFilter,
    WeeklyPlanningDashboardHeader,
    WeeklyPlanningDashboardConsolidated,
    WeeklyPlanningDashboardChartDemandFullfilmentRevised,
    WeeklyPlanningDashboardChartIbpDemandFullfilmentRevised,
    WeeklyPlanningDashboardChartBookingProjectionWithoutTetheringRevised,
    WeeklyPlanningDashboardChartStorageProjection,
    WeeklyPlanningDashboardChartStuffingProjection,
    WeeklyPlanningDashboardChartLoadingPlan,
    WeeklyPlanningDashboardChartTakeOrPayProjectionStuffing,
    WeeklyPlanningDashboardChartTakeOrPayProjectionStorage,
    WeeklyPlanningDashboardChartAvailableStock,
    WeeklyPlanningDashboardChartAvailableBookingXAvailableSale,
    WeeklyPlanningDashboardChartSalesWithoutAllocation,
    WeeklyPlanningDashboardChartSalesWithoutAllocationXAvailableStock
  },
  data() {
    return {
      consolidated: {},
      scenarioOpen: useScenarioOpen(),
      measurementUnit: dashboardUtils.getUnityTonne(),
      selectedPeriod: {
        start: undefined,
        end: undefined
      }
    }
  },
  created() {
    this.resetFilter()
  },
  methods: {
    resetFilter() {
      this.selectedPeriod = {
        start: this.scenario.beginHorizon,
        end: this.scenario.endHorizon
      }
    },
    onChangeMeasurementUnit(newValue) {
      this.measurementUnit = newValue
    }
  },
  computed: {
    scenario() {
      return this.scenarioOpen.getScenarioOpen()
    },
    baseFilter() {
      return {
        startDate: this.selectedPeriod.start,
        endDate: this.selectedPeriod.end,
        unity: this.measurementUnit.value
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.weekly-planning-dashboard {
  &__charts {
    margin-top: 10px;
    padding-bottom: 10px;
  }
}
</style>
