import { dashboardUtils } from '@cargill/shared'

const baseURL = 'api/weeklyPlanningRevised'

const service = {}

service.getConsolidated = async (filter) => {
  const url = `${baseURL}/consolidated`
  return await dashboardUtils.filterDashboard(filter, url)
}

service.getIbpDemandFullfilment = async (oldChart, baseFilter) => {
  const url = `${baseURL}/ibpDemandFullfilment`
  const processChart = (chart) =>
    chart.series.forEach((serie, i) => (serie.order = i))
  return await dashboardUtils.filterChart(
    oldChart,
    baseFilter,
    url,
    processChart
  )
}

service.getWeeklyDemandFullfilmentExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/weeklyDemandFullfilmentExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getWeeklyDemandFullfilment = async (oldChart, baseFilter) => {
  const url = `${baseURL}/weeklyDemandFullfilment`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getIbpDemandFullfilmentExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/ibpDemandFullfilmentExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getBookingProjectionWithoutTethering = async (oldChart, baseFilter) => {
  const url = `${baseURL}/bookingProjectionWithoutTethering`
  const processChart = (chart) => (chart.series[1].yAxisID = 'yQuantityBooking')
  return await dashboardUtils.filterChart(
    oldChart,
    baseFilter,
    url,
    processChart,
    false
  )
}

service.getBookingProjectionWithoutTetheringExcel = async (
  chartRef,
  baseFilter
) => {
  const url = `${baseURL}/bookingProjectionWithoutTetheringExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getStorageProjection = async (oldChart, baseFilter) => {
  const url = `${baseURL}/storageProjection`
  return await dashboardUtils.filterChart(
    oldChart,
    baseFilter,
    url,
    null,
    false
  )
}

service.getStorageProjectionExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/storageProjectionExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getStuffingProjection = async (oldChart, baseFilter) => {
  const url = `${baseURL}/stuffingProjection`
  return await dashboardUtils.filterChart(
    oldChart,
    baseFilter,
    url,
    null,
    false
  )
}

service.getStuffingProjectionExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/stuffingProjectionExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getTakeOrPayProjectionStorage = async (oldChart, baseFilter) => {
  const url = `${baseURL}/takeOrPayProjectionStorage`
  return await dashboardUtils.filterChart(
    oldChart,
    baseFilter,
    url,
    null,
    false
  )
}

service.getTakeOrPayProjectionStorageExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/takeOrPayProjectionStorageExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getTakeOrPayProjectionStuffing = async (oldChart, baseFilter) => {
  const url = `${baseURL}/takeOrPayProjectionStuffing`
  return await dashboardUtils.filterChart(
    oldChart,
    baseFilter,
    url,
    null,
    false
  )
}

service.getTakeOrPayProjectionStuffingExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/takeOrPayProjectionStuffingExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getAvailableStock = async (oldChart, baseFilter) => {
  const url = `${baseURL}/availableStock`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getAvailableStockExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/availableStockExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getSalesWithoutAllocation = async (oldChart, baseFilter) => {
  const url = `${baseURL}/salesWithoutAllocation`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getSalesWithoutAllocationExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/salesWithoutAllocationExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getLoadingPlan = async (oldChart, baseFilter) => {
  const url = `${baseURL}/loadingPlan`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getLoadingPlanExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/loadingPlanExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getSalesWithoutAllocationXAvailableStock = async (
  oldChart,
  baseFilter
) => {
  const url = `${baseURL}/salesWithoutAllocationXAvailableStock`
  return await dashboardUtils.filterChart(
    oldChart,
    baseFilter,
    url,
    null,
    false
  )
}

service.getSalesWithoutAllocationXAvailableStockExcel = async (
  chartRef,
  baseFilter
) => {
  const url = `${baseURL}/salesWithoutAllocationXAvailableStockExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getAvailableBookingXAvailableSale = async (oldChart, baseFilter) => {
  const url = `${baseURL}/availableBookingXAvailableSale`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getAvailableBookingXAvailableSaleExcel = async (
  chartRef,
  baseFilter
) => {
  const url = `${baseURL}/availableBookingXAvailableSaleExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

export default service
