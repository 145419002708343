<template>
  <dashboard-chart
    class="weekly-planning-dashboard-chart-booking-projection-without-tethering"
    :base-filter="baseFilter"
    :on-load="onLoad"
    :on-export="onExport"
    :supressChartStack="true"
  >
  </dashboard-chart>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import { DashboardChart } from '../dashboards'
import service from '../../api/weeklyPlanningService'

export default defineComponent({
  name: 'WeeklyPlanningDashboardChartBookingProjectionWithoutTethering',
  components: { DashboardChart },
  props: {
    baseFilter: {
      type: Object,
      required: true
    }
  },
  methods: {
    async onLoad(chartReference) {
      const chart = await service.getBookingProjectionWithoutTethering(
        chartReference,
        this.baseFilter
      )
      return chart
    },
    async onExport(chartReference) {
      await service.getBookingProjectionWithoutTetheringExcel(
        chartReference,
        this.baseFilter
      )
    }
  }
})
</script>
