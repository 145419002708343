<template>
  <div class="box-wrapper">
    <div class="box" v-if="!custom">
      <p>{{ title }}</p>
      <div class="box-flex box-value">
        <span class="prefix-unity" v-show="unityPrefix != null">
          {{ unityPrefix }}
        </span>
        <span>{{ formatNumber(value) }}</span>
        <span class="unity" v-show="unity != null"> {{ unity }}</span>
      </div>
      <p v-show="caption != null">
        <span class="box-caption"
          >{{ formatNumber(captionValue) }} {{ _captionUnity }}
        </span>
        <b>{{ caption }}</b>
      </p>
      <p v-show="subCaption != null">
        <span class="box-caption"
          >{{ formatNumber(subCaptionValue) }} {{ _subCaptionUnity }}
        </span>
        <b>{{ subCaption }}</b>
      </p>
    </div>
    <div class="box" v-if="custom">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { useUserSettings } from '@cargill/shared'

const { getLanguage } = useUserSettings()

export default {
  name: 'DataBox',
  props: {
    title: {
      type: String,
      default: null
    },
    value: {
      type: Number,
      default: 0
    },
    unityPrefix: {
      type: String,
      default: null
    },
    unity: {
      type: String,
      default: null
    },
    caption: {
      type: String,
      default: null
    },
    captionValue: {
      type: Number,
      default: 0
    },
    captionUnity: {
      type: String,
      default: null
    },
    subCaption: {
      type: String,
      default: null
    },
    subCaptionValue: {
      type: Number,
      default: 0
    },
    subCaptionUnity: {
      type: String,
      default: null
    },
    custom: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    formatNumber(value) {
      return Number.isNaN(value) ? value : value.toLocaleString(getLanguage())
    }
  },
  computed: {
    _captionUnity() {
      return this.captionUnity ?? this.unity
    },
    _subCaptionUnity() {
      return this.subCaptionUnity ?? this.unity
    }
  }
}
</script>
<style lang="scss" scoped>
.box-wrapper {
  color: #959db5;
  text-transform: uppercase;

  .box {
    max-width: 12vw;
    height: 10vw;
    background: #2b3143 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 10px 5px 0px 5px;
    font-size: 0.7vw;
    text-align: left;
  }

  .box-flex {
    display: flex;
  }

  .box-value {
    align-items: baseline;
    font-size: 1.5vw;
    color: #24bbef;
    font-weight: 900;
    line-height: normal;
    .unity {
      font-size: 1.2vw;
      margin-left: 5px;
    }
    .prefix-unity {
      font-size: 2vw;
      margin-right: 5px;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }

  .box-caption {
    font-size: 0.78vw;
    color: #24bbef;
    font-weight: bold;
  }
}
</style>
