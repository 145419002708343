<template>
  <cargill-section
    v-if="consolidated"
    :layer="3"
    class="weekly-planning-dashboard-consolidated"
  >
    <cargill-section-header color="#2B323F">
      {{ consolidated.title }}
    </cargill-section-header>

    <cargill-section-content
      class="weekly-planning-dashboard-consolidated__container"
    >
      <bc-container class="weekly-planning-dashboard-consolidated__container">
        <bc-row dense>
          <bc-col
            v-for="(kpi, i) in consolidated.kpis"
            :key="kpi.title"
            :cols="defineColumnSize(i)"
          >
            <dashboard-kpi :kpi="kpi"></dashboard-kpi>
          </bc-col>
        </bc-row>
      </bc-container>
    </cargill-section-content>
  </cargill-section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import {
  CargillSection,
  CargillSectionHeader,
  CargillSectionContent
} from '../cargill-section'
import { DataBox, WeeklyDashboardDetailBox } from '../data-box'
import { DashboardKpi } from '../dashboards'
import service from '../../api/weeklyPlanningService.js'

export default defineComponent({
  name: 'WeeklyPlanningDashboardConsolidated',
  components: {
    CargillSection,
    CargillSectionHeader,
    CargillSectionContent,
    DataBox,
    WeeklyDashboardDetailBox,
    DashboardKpi
  },
  props: {
    scenario: {
      type: Object,
      required: true
    },
    unit: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      consolidated: {}
    }
  },
  mounted() {
    this.loadConsolidated()
  },
  methods: {
    async loadConsolidated() {
      this.consolidated = await service.getConsolidated({
        unity: this.unit.value
      })
    },
    defineColumnSize(columnIndex) {
      if (columnIndex <= 3) {
        return 3
      }

      return 4
    }
  },
  computed: {
    cardDetails() {
      if (this.consolidated) {
        return [
          this.consolidated.ibpDemandFullfilment,
          this.consolidated.demandFullfilment,
          this.consolidated.originationProjection
        ].filter((x) => x)
      }
      return []
    },
    cards() {
      if (this.consolidated) {
        return [
          this.consolidated.bookingsUsedProjection,
          this.consolidated.openContractsProjection,
          this.consolidated.allocatedCottonProjection,
          this.consolidated.stuffingCapacityProjection
        ].filter((x) => x)
      }
      return []
    }
  },
  watch: {
    unit() {
      this.loadConsolidated()
    }
  }
})
</script>

<style lang="scss" scoped>
.weekly-planning-dashboard-consolidated {
  &__container {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 15px 10px;

    .row {
      .col-xl-2 {
        flex: 0 0 13.88%;
        flex-grow: 1;
      }
    }
  }
}
</style>
