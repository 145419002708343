import { render, staticRenderFns } from "./DataBox.vue?vue&type=template&id=17fdbebb&scoped=true&"
import script from "./DataBox.vue?vue&type=script&lang=js&"
export * from "./DataBox.vue?vue&type=script&lang=js&"
import style0 from "./DataBox.vue?vue&type=style&index=0&id=17fdbebb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17fdbebb",
  null
  
)

export default component.exports